<template>
    <a-modal v-model="show" title="招聘信息" :width="800" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
        <a-form-model ref="formRef" :model="formData" :rules="formRule">
            <a-row :gutter="15">
                <a-col span="12">
                    <a-form-model-item label="招聘岗位" label-position="top" prop="title">
                        <a-input v-model="formData.title" placeholder="请输入招聘岗位"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col span="6" v-if="getOrganId() == 0">
                    <a-form-model-item label="所属单位" label-position="top" prop="organId">
                        <a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位">
                            <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
                                organ.name }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col span="6">
                    <a-form-model-item label="发布状态" label-position="top" prop="status" required>
                        <a-select v-model="formData.status">
                            <a-select-option value="未开放">未开放</a-select-option>
                            <a-select-option value="开放中">开放中</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <!-- <a-col span="6">
                    <a-form-model-item label="图片" label-position="top" prop="thumb">
                        <up-img ref="upimg" @success="e => formData.thumb = e" :thumb="formData.thumb"></up-img>
                    </a-form-model-item>
                </a-col> -->
            </a-row>
            <a-form-model-item prop="content">
                <editor v-model="formData.content" />
            </a-form-model-item>
        </a-form-model>
        <a-spin size="large" fix v-if="loading" />
    </a-modal>
</template>

<script>
import request from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
import editor from '@/components/component/editor.vue';
import upImg from '@/components/common/upImg.vue';
export default {
    components: { editor, upImg },
    data() {
        return {
            show: false,
            loading: false,
            organList: apiUtil.getOrganList(),
            formData: {
                jobId: '',
                organId: apiUtil.getOrganId(''),
                title: '',
                content: '',
                status: '开放中',
                // thumb: ''
            },
            formRule: {
                title: [{ required: true, message: '请填写招聘岗位' }],
                content: [{ required: true, message: '请填写招聘详情' }],
                organId: [{ required: true, message: '请选择所属单位' }],
            }
        };
    },
    methods: {
        ...apiUtil,
        _show(form) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.formRef.resetFields();
                if (form) {
                    this.formData = JSON.parse(JSON.stringify(form));
                } else {
                    this.formData.jobId = '';
                    this.formData.content = '';
                }
            });
        },
        _close() {
            this.$refs.formRef.resetFields();
            this.show = false;
            this.$emit('close');
        },
        doSubmit() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    // console.log(this.formData.managerDepIds); return;
                    utils.showSpin();
                    request.post('/platform/job/save', this.formData).then(ret => {
                        this.$refs.formRef.resetFields();

                        // console.log(ret)
                        this.show = false;
                        if (ret.code == 200) {
                            utils.success('操作成功！').then(() => {
                                this.show = false;
                                this.$emit('success');
                            });
                        }
                    });
                }
            });
        }
    }
};
</script>

<style scoped>
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
</style>
